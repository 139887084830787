import _swaggerUiBundle from "./swagger-ui-bundle.js";
import _swaggerUiStandalonePreset from "./swagger-ui-standalone-preset.js";
import _absolutePath from "./absolute-path.js";
var exports = {};
try {
  exports.SwaggerUIBundle = _swaggerUiBundle;
  exports.SwaggerUIStandalonePreset = _swaggerUiStandalonePreset;
} catch (e) {
  // swallow the error if there's a problem loading the assets.
  // allows this module to support providing the assets for browserish contexts,
  // without exploding in a Node context.
  //
  // see https://github.com/swagger-api/swagger-ui/issues/3291#issuecomment-311195388
  // for more information.
}

// `absolutePath` and `getAbsoluteFSPath` are both here because at one point,
// we documented having one and actually implemented the other.
// They were both retained so we don't break anyone's code.
exports.absolutePath = _absolutePath;
exports.getAbsoluteFSPath = _absolutePath;
export default exports;
export const SwaggerUIBundle = exports.SwaggerUIBundle,
  SwaggerUIStandalonePreset = exports.SwaggerUIStandalonePreset,
  absolutePath = exports.absolutePath,
  getAbsoluteFSPath = exports.getAbsoluteFSPath;